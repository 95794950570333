.thumbnail-slider-wrap {
  margin-top: 15px !important;
  /*height: 60px !important;*/
}
.thumbnail-slider-wrap .slick-track .slick-slide {
  text-align: center !important;
}
.thumbnail-slider-wrap .slick-track .slick-slide img {
  width: calc(100% - 10px) !important;
  height: 100px !important;
  /*border: 1px black solid !important;*/
  border: 1px solid #bbdefb;
}
.slick-track {
  display: flex !important;
}

.slick-slide {
  height: auto;
}
.slick-prev {
  left: 0;
}
.slick-next {
  right: 0;
}
.slick-next,
.slick-prev {
  z-index: 2;
}

.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  color: #ffa000;
}

/* .slider-wrapper .slick-track .slick-slide img {
	width: 80% !important;
	margin-left: auto;
	margin-right: auto;
} */

.slick-slide {
  text-align: center;
  position: static;
}
.slick-slide:focus {
  outline: none;
}
.slick-slide-title {
  text-transform: capitalize;
}
.slick-slide-image {
  max-width: 100%;
  height: auto;
  /*border-radius: 8px;
  box-shadow: 0 13px 27px -5px hsla(240, 30.1%, 28%, 0.25),
    0 8px 16px -8px hsla(0, 0%, 0%, 0.3), 0 -6px 16px -6px hsla(0, 0%, 0%, 0.03);*/
}
.slick-slide-label {
  color: #fff;
  padding: 10px;
  position: absolute;
  left: 0px;
  font-size: 1.5em;
  bottom: 0px;
  width: 100%;
}
.slick-prev:before,
.slick-next:before {
  color: #777777;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}
.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}
.ms-0 {
  margin-left: 0 !important;
}

@media (min-width: 992px) {
  .ps-lg-5 {
    padding-left: 3rem !important;
  }
}

@media (min-width: 992px) {
  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }
}

.me-2 {
  margin-right: 0.5rem !important;
}

.search-form-select-wrapper {
  display: flex;
  background-color: #f4f4f4;
  border-radius: 0 1.3rem 1.3rem 0;
  width: 50%;
}

.input-group
  > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.search-with-select
  .search-form-wrapper
  .search-form-select-wrapper
  .custom-select-1
  select {
  background-color: transparent;
  border: 1px solid #fff;
  border-top-width: 0;
  border-bottom-width: 0;
  border-radius: 0;
  color: #8d8d8d;
  height: 100%;
  z-index: 1;
}

.search-with-select .search-form-wrapper .search-form-select-wrapper .btn {
  background: 0 0 !important;
  border: 0;
  box-shadow: none !important;
}

.icon-magnifier {
  font-weight: 900;
  font-size: 16px !important;
  line-height: 26px;
}

.input-group .btn {
  position: relative;
  z-index: 2;
}

@media (min-width: 1200px) {
  .ps-xl-0 {
    padding-left: 0 !important;
  }
}

@media (min-width: 992px) {
  .ms-lg-3 {
    margin-left: 1rem !important;
  }
}

@media (min-width: 1200px) {
  .ps-xl-3 {
    padding-left: 1rem !important;
  }
}

.pe-0 {
  padding-right: 0 !important;
}

.ms-3 {
  margin-left: 1rem !important;
}
.pt-5 {
  padding-top: 3rem !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}
.mb-3 {
  margin-bottom: 1rem !important;
}
.me-1 {
  margin-right: 0.25rem !important;
}

.pl-2,
.px-2 {
  padding-left: 10px !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

@media (min-width: 992px) {
  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.float-end {
  float: right !important;
}

.img-thumbnail {
  background-color: #fff !important;
  max-width: 100% !important;
  height: 600px;
}

.owl-carousel,
.owl-carousel.owl-loaded {
  max-height: 250px;
}

.owl-carousel .owl-stage-outer {
  height: 100% !important;
}

.owl-carousel .owl-stage {
  height: 100% !important;
  white-space: nowrap !important;
  overflow-x: visible !important;
}
@media only screen and (max-width: 600px) {
  .card-body .col-lg-6 {
    width: 50%;
  }
}

@media only screen and (max-width: 600px) {
  .owl-carousel,
  .owl-carousel.owl-loaded {
    max-height: 80px !important;
  }
}

@media only screen and (max-width: 600px) {
  .col-md-6.pl-5 {
    padding-left: 15px !important;
  }
}

.fa-chevron-down {
  display: none !important;
}

@media only screen and (max-width: 600px) {
  .card {
    height: 380px !important;
  }
}

.ml-3,
.mx-3 {
  margin-left: 0px !important;
}

.mr-2,
.mx-2 {
  margin-right: 10px !important;
}

@media (min-width: 1200px) {
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 0px !important;
  }
}

.fullImage {
  min-height: 255px;
}

.fullLength {
  min-height: 130px;
}

.header-nav-features-toggle {
  color: #ffa000;
}

.header-nav-features-toggle:hover {
  color: #ffa000;
}
.topCarousel {
  height: 100% !important;
  max-height: 373px !important;
}
